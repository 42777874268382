<template>
<b-container class="bv-example-row" >
        <div class="mt-2 mb-2 p-3 border cardHover" v-for="item in topTenNovel" :key="item.novel_id">
            <a class="text-decoration-none text-default-n" :href="'/novel/' + item.novel_id">
                <b-row class="justify-content-center">
                    <b-col cols="6" sm="3">
                        <b-img-lazy :src="item.novel_cover" fluid class="novelCover" @error.native="img_load_default_cover"></b-img-lazy>
                    </b-col>
                    <b-col cols="12" sm="9" class="sm-center">
                        <div style="fontSize:18px;fontWeight:bold">{{item.novel_title}}</div>
                        <div>ตอนที่ {{item.first_ep}} - {{item.last_ep}}</div>
                        <div>หมวดหมู่: <span class="category">{{item.novel_tag}}</span></div>
                        <a-divider class="d-none d-sm-block" />
                        <div class="novel_desc d-none d-sm-block" v-html="removeTags(item.novel_desc)"></div>
                    </b-col>
                </b-row>
            </a>
        </div>
</b-container>
</template>

<script>
export default {
    props: ['topTenNovel', 'index'],
    methods: {
        img_load_default_cover(event){
            //console.log("Image broken -> Load default")
            event.target.src = require("@/assets/default_cover.jpg")
        },
        removeTags(str) { 
            if ((str===null) || (str==='')) 
                return false; 
            else
                str = str.replace( /<br ?\/?>/g, "\n");
                str = str.toString(); 
                
            // Regular expression to identify HTML tags in  
            // the input string. Replacing the identified  
            // HTML tag with a null string. 
            str = str.replace( /(<([^>]+)>)/ig, ''); 
            return str.replace("\n", "<br>");
        } 
    }
};
</script>

<style scoped>
.category {
    color: #ffffff;
    background-color: #EE806B;
    padding: 3px 7px;
    border-radius: 5px;
}

.text-default-n{
    color: inherit;
}

.novelCover {
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.cardHover:hover {
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .novelCover {
        margin-bottom:5px;
    }

    .ant-tabs {
        width: 98%;
    }

    .sm-center{
        text-align: center;
    }
}

.novel_desc {
    height: 6em;
    overflow: hidden;
    font-size: 14px;
}
</style>
