<template>
<div class="card-container">
    <a-tabs type="card" v-if="novelTypes=='topTenTranslate'">
        <a-tab-pane v-for="(index_trans,topTenTransNovel) in topTenTranslate" :tab="topTenTransNovel+1" :key="topTenTransNovel">
            <TopNovelCard v-if="novelTypes=='topTenTranslate'" :topTenNovel="topTenTranslate[topTenTransNovel]" :index="index_trans" />
        </a-tab-pane>
    </a-tabs>
    <a-tabs type="card" v-if="novelTypes=='topTenCreate'">
        <a-tab-pane v-for="(index_create,topTenCreateNovel) in topTenCreate" :tab="topTenCreateNovel+1" :key="topTenCreateNovel">
            <TopNovelCard v-if="novelTypes=='topTenCreate'" :topTenNovel="topTenCreate[topTenCreateNovel]" :index="index_create" />
        </a-tab-pane>
    </a-tabs>
    
</div>
</template>

<script>
import TopNovelCard from './../elements/TopNovelCard.vue'
import {
    mapGetters
} from 'vuex';

export default {
    props: ['novelTypes'],
    components: {
        TopNovelCard
    },
    mounted() {
        this.checkMode()
        //console.log(this.novelTypes)
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            topTenTranslate: 'GET_TOPTEN_TRANSLATE_NOVEL',
            topTenCreate: 'GET_TOPTEN_CREATE_NOVEL',
            mode: 'GET_MODE'
        })
    },
    methods: {
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('card-container').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            document.getElementsByClassName('card-container').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    },
};
</script>

<style>
.card-container {
    overflow: hidden;
    padding: 10px 0px 10px 0px;
    width: 102%;
}

.card-container>.ant-tabs-card>.ant-tabs-content {
    margin-top: -16px;
}

.card-container>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}

.card-container>.ant-tabs-card>.ant-tabs-bar {
    border-color: #fff;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: #849EB4;
    color: #ffffff;
    margin-right: 5px;
    margin-bottom: -1px;
    width: 60px;
    text-align: center;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
    color: #849EB4;
    font-size: 19px;
    text-align: center;
}

.card-container>.ant-tabs-card>.ant-tabs-bar{
    border-bottom: none;
}

.darkmode>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
    background: #35363A;
    color: #ffffff;
}

.darkmode>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
    border-color: #35363A;
    background: #35363A;
    color: #fff;
}
</style>
